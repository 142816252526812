import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Dropdown`}</h1>
    <p>{`Dropdown provides multiple choices
that cause an action when selected.`}</p>
    <ComponentPreview componentName="dropdown--default-story" hasReact hasAngular hasHTML minHeight="15rem" titleAttr="Dropdown Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`A Dropdown displays a list of contextual options,
triggered by an interaction with another element
(like an Icon or Button). Once a selection is made,
some action will occur (e.g. navigate to another page).`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Dropdown is not the same as the HTML
Select element and should not be used in a form.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Base`}</h3>
    <p>{`Base Dropdown is primarily used as a context menu.
For example, when the user clicks on the user
icon in the Masthead, it opens a Base Dropdown
with links to account settings.`}</p>
    <ComponentPreview componentName="dropdown--default-story" hasReact hasAngular hasHTML minHeight="15rem" titleAttr="Base Dropdown Example" mdxType="ComponentPreview" />
    <h3>{`Informational`}</h3>
    <p>{`Informational Dropdown is an expanded version
of the Base Dropdown. Each choice has a title
and additional information. Additionally, it
has an optional secondary action.`}</p>
    <ComponentPreview componentName="dropdown--informational" hasReact hasAngular hasHTML minHeight="27rem" titleAttr="Informational Dropdown Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Dropdown must have an element to trigger
the Dropdown (e.g. a Button or Icon).`}</li>
      <li parentName="ul">{`Dropdown must have a list of choices.`}</li>
      <li parentName="ul">{`Dropdown may have a title above the list
of choices.`}</li>
      <li parentName="ul">{`Informational Dropdown must have additional
information about each choice.`}</li>
      <li parentName="ul">{`Informational Dropdown may have a secondary
action at the bottom (e.g. a Link or Button).`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      